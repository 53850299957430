import * as Sentry from "@sentry/browser";
import type { SeverityLevel } from "@sentry/types";
import canopyUrls from "canopy-urls!sofe";
import * as singleSpaHelpers from "./single-spa.helpers.js";
import * as urlHashHelpers from "./url-hash.helpers.js";
import { beforeSend, handleToaster } from "./sentry.helper.js";

export { getSquad as serviceNameToSquad } from "./error.helpers.js";
export { ErrorBoundary } from "./error-boundary";

interface CustomError extends Error {
  service?: string;
  showToast?: boolean;
  toastMessage?: string;
  status?: number;
  skipLogging?: boolean;
  tags?: Record<string, string>;
}

type ErrorOptions = {
  showToast?: boolean;
  toastMessage?: string;
  skipLogging?: boolean;
  redirectOrCatch?: boolean;
  tags?: Record<string, string>;
};

type SentryConfig = {
  tags?: Record<string, string>;
  [key: string]: unknown;
};

let suppressErrors = false;

export function setupHandleError(service: string, config: SentryConfig = {}) {
  return (error: CustomError, opts: ErrorOptions = {}) => {
    error.service = service;
    const { showToast, toastMessage, skipLogging, redirectOrCatch, ...rest } =
      opts;

    if (typeof showToast === "boolean") {
      error.showToast = showToast;
    }
    if (typeof toastMessage === "string") {
      error.toastMessage = toastMessage;
    }
    if (redirectOrCatch) {
      // migrated from fetcher
      if (error.status === 404 || error.status === 403) {
        const findString = /\/(api|wg)\/\S+/.exec(error.message);
        const urlString = findString ? findString[0] : "A request";
        console.info(`${urlString} returned ${error.status}, redirecting`);
        window.history.replaceState(null, "", `/#/${error.status}`);
        return;
      }
    }

    let updatedError = handleToaster(error);

    if (skipLogging || error?.skipLogging) return;
    console.error(error);

    const options: SentryConfig = { ...config, ...rest };
    captureException(updatedError, options);
  };
}

export function init(version: string) {
  if (typeof version !== "string") {
    throw new Error(
      `sentry must be initialized with a string "version" of the code`
    );
  }

  singleSpaHelpers.init();
  urlHashHelpers.init();

  if (canopyUrls.getEnvironment() === canopyUrls.DEVELOPMENT_ENVIRONMENT) {
    locallyTrackErrors();
    return; // We don't want to do sentry handling in development
  }

  loadSentry(version);
}

function locallyTrackErrors() {
  const errCallback = window.onerror;
  window.onerror = function (
    message: string | Event,
    source?: string,
    line?: number,
    col?: number,
    err?: Error
  ) {
    if (errCallback) {
      errCallback.call(window, message, source, line, col, err);
    }
    if (!suppressErrors) {
      handleToaster(err);
    }
  };
}

type LoggedInUser = {
  email: string;
  name: string;
  id: string;
  role: string;
};

export function setUser(user: LoggedInUser) {
  sentrySetUser(user);
}

// this is exported for being able to add breadcrumbs to sentry when it becomes important to debug something.
// see https://docs.sentry.io/enriching-error-data/breadcrumbs/ for documentation
export function addBreadcrumb(
  message: string,
  category = "misc",
  level: SeverityLevel = "info"
) {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
}

type MessageExtraData = {
  level?: SeverityLevel;
  tags?: Record<string, string>;
  [key: string]: unknown;
};

export function captureMessage(
  msg: string | Error,
  extraData: MessageExtraData = {}
) {
  // TODO: until next week. then undo all this
  return;
  // const messageStr = typeof msg === "string" ? msg : msg.message;

  // I don't think this is necessary but before moving to typescript we were setting showToast to false on the msg object
  // From what I can tell we're usually passing in an error and not a string so this code should be re-evaluated
  // I'm going to keep it here for now in case consumers are relying on this
  //   if (msg.hasOwnProperty("showToast")) {
  //     (msg as any).showToast = false;
  //   }

  //   const level = extraData.level || "info";
  //   const tags = extraData.tags || {};
  //   delete extraData.tags;
  //   delete extraData.level;
  //   addTags(tags);

  //   Sentry.withScope((scope) => {
  //     for (let tag in tags) {
  //       if (tags.hasOwnProperty(tag)) scope.setTag(tag, tags[tag]);
  //     }

  //     for (let extra in extraData) {
  //       if (extraData.hasOwnProperty(extra))
  //         scope.setExtra(extra, extraData[extra]);
  //     }

  //     Sentry.captureMessage(messageStr, level);
  //   });
}

// The default argument will not guarantee the presence of extraData.tags
export function captureException(
  ex: any,
  extraData: SentryConfig = { tags: {} }
) {
  const tags = extraData.tags || {};
  delete extraData.tags;
  addTags(tags);

  Sentry.withScope((scope) => {
    for (let tag in tags) {
      if (tags.hasOwnProperty(tag)) scope.setTag(tag, tags[tag]);
    }

    for (let extra in extraData) {
      if (extraData.hasOwnProperty(extra))
        scope.setExtra(extra, extraData[extra]);
    }

    Sentry.captureException(ex);
  });
}

function addTags(tags: Record<string, string>) {
  singleSpaHelpers.addActiveApps(tags);
  urlHashHelpers.addUrlHash();
}

function sentrySetUser(loggedInUser: LoggedInUser) {
  Sentry.configureScope((scope) => {
    if (loggedInUser) {
      scope.setUser({
        email: loggedInUser.email,
        name: loggedInUser.name,
        id: loggedInUser.id,
        role: loggedInUser.role,
      });

      //Allows search by userEmail and userName in sentry
      scope.setTag("userEmail", loggedInUser.email);
      scope.setTag("userName", loggedInUser.name);
      scope.setTag("userRole", loggedInUser.role);
    }
  });
}

function loadSentry(version: string) {
  const dashIndex = version.indexOf("-");
  let release = dashIndex >= 0 ? version.substring(0, dashIndex) : version;

  Sentry.init({
    dsn: "https://8ca7bd5b6afd45e389d5cc45dd8ab1a1@o4504080391733248.ingest.sentry.io/4504080460480512",
    beforeSend: beforeSend,
    environment: canopyUrls.getEnvironment(),
    maxValueLength: 5000,
    release,
    ignoreErrors: [
      "AbortError",
      "Can't find variable: gmo",
      "ChunkLoadError",
      "Error: ResizeObserver loop limit exceeded",
      /Error: (.*) died in status BOOTSTRAPPING(.*)/,
      "Failed to fetch",
      "Load failed",
      "Missing credentials cookie",
      "Synchronous XHR in page dismissal",
      "TypeError: Cancelled",
      "TypeError: cancelled",
      "TypeError: Load failed",
      "TypeError: NetworkError when attempting to fetch resource.",
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag("version", version);
    scope.setTag("environment", canopyUrls.getEnvironment());

    singleSpaHelpers.sentryLoaded(scope);
    urlHashHelpers.sentryLoaded();
  });
}
